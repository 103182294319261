import "./styles.scss";

import React, { Component } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { IObject } from "../../../../interfaces/product-swagger.interface";

interface IProps {
  linkElement: IObject;
  index: number;
}

interface IStates {
  linkOpened: boolean;
}

class LinkPart extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      linkOpened: false,
    };
  }

  private blockClicked = () => {
    const linkOpened = !this.state.linkOpened;
    this.setState({ linkOpened });
  };

  render() {
    return (
      <div id="link">
        <div className="link-content">
          <div className="link-title" onClick={() => this.blockClicked()}>
            <span dangerouslySetInnerHTML={{__html:this.props.linkElement.title.replace(
                                    /<p>/g,
                                    '<p style="margin:0px">'
                                  )}}/>
            {this.state.linkOpened ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </div>
          {this.state.linkOpened && (
            <div className="link-response" dangerouslySetInnerHTML={{__html:this.props.linkElement.response.toString().replace(
              /<p>/g,
              '<p style="margin:0px">'
            )}}/>
          )}
        </div>
      </div>
    );
  }
}

export default LinkPart;
