import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

import HeaderBanner from "../../../common/components/headerBanner";
import { Helmet } from 'react-helmet';
import BracedTitle from "../../../common/components/bracedTitle";
import QuestionFaq from "./questionFaq";
import { IQuestionFaq, IRegardingFaq } from "../interfaces/faq.interface";
import SearchInput from "../../../common/submodules/form/components/searchInput";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import BlockFamily from "./blockFamily";
import { ICms } from "../../../productRoadmap/interfaces/cms.interface";
import CmsAPI from "../../../productRoadmap/apis/apiCms.api";
import { IFaqSections,IFaqQuestions } from "../../../productRoadmap/interfaces/new-product.interface";
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps
  extends RouteComponentProps,
    WrappedComponentProps,
    PropsFromRedux {}

interface IStates {
  searchValue: string;
  blocksActivated: string[];
  listCms: ICms | undefined;
}

class Faq extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      searchValue: "",
      blocksActivated: [],
      listCms: undefined
    };
  }
  componentDidMount() {
    CmsAPI.getFaqCms().then((res: any) => {
      let filteredResponse = {...res};
      filteredResponse.faqSections = filteredResponse.faqSections.filter((section:IFaqSections) => section.shown === true);
      this.setState({ listCms: filteredResponse });
    })
  }
  private displayFamily = (family: IFaqSections, index: number) => {
    let questionsFamily = this.state.listCms?.faqQuestions;
    questionsFamily = questionsFamily?.filter((question: IFaqQuestions) =>
      question.faqSections.some((faqSection) => faqSection.sectionId === family.id)
    )
    .sort((a: IFaqQuestions, b: IFaqQuestions) => {
      const sectionA = a.faqSections.find(faqSection => faqSection.sectionId === family.id);
      const sectionB = b.faqSections.find(faqSection => faqSection.sectionId === family.id);
      // Use fallback values if questionOrder is undefined (e.g., Infinity to push undefined to the end)
      const orderA = sectionA?.questionOrder ?? 0;
      const orderB = sectionB?.questionOrder ?? 0;

      return orderA - orderB;
    }); 
    if (this.state.searchValue !== "") {
      questionsFamily = questionsFamily?.filter((question: IFaqQuestions) =>
        question.question
          .toLowerCase()
          .includes(this.state.searchValue.toLowerCase())
      );
    }
    if (
      this.state.blocksActivated.length == 0 ||
      this.state.blocksActivated.includes(family.title)
    ) {
      return (
        questionsFamily && questionsFamily.length> 0 && (
          <div key={`faq-family-${index}`} className="questions">
            <BracedTitle text={family.title} />
            {questionsFamily.map((question: IFaqQuestions) => (
              <QuestionFaq key={question.id} question={question} />
            ))}
          </div>
        )
      );
    }
  };
  
  private onSearch = (searchValue: string) => {
    this.setState({ searchValue });
  };

  private blockClicked = (familyName: string) => {
    const blocksActivated = this.state.blocksActivated;
    if (blocksActivated.includes(familyName)) {
      blocksActivated.splice(blocksActivated.indexOf(familyName), 1);
    } else {
      blocksActivated.push(familyName);
    }
    this.setState({ blocksActivated });
  };

  render() {
    return (
      <div id="faq" className={this.props.screenType}>
        <Helmet>
          <title>Faq - API Portal - CMA CGM</title>
        </Helmet>
        <HeaderBanner>
          <div className="header-banner-content">
            <span>
              <FormattedMessage id="faq.head.title" />
            </span>
            <SearchInput isAsynchronous={false} onSearch={this.onSearch} />
          </div>
        </HeaderBanner>
        <div className="App-content">
          <div className="summary">
            {this.state.listCms?.faqSections?.map((section) => (
              <BlockFamily
                key={section.id}
                familyName={section.title}
                blockClicked={(familyName) => this.blockClicked(familyName)}
              />
            ))}
          </div>
          {this.state.listCms?.faqSections?.map(this.displayFamily)}
        </div>
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(injectIntl(connector(Faq)));
