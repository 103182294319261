import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router";

import { Routes } from "../../../common/enums/routes.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { statuses } from "../../../myOrganization/clientSettings/constants/userStatus.constant";
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends RouteProps, PropsFromRedux {}

class PrivateRoute extends Component<IProps> {
  render() {
    if (this.props.user) {
      if (this.props.path === Routes.CLIENT_SETTINGS || this.props.path === Routes.BUSINESS_PARTNERS) {
        if (this.props.user.clientStatus === statuses.DEACTIVATED) {
          return <Redirect to={Routes.HOME} />;
        }
        return <Route path={this.props.path} component={this.props.component} />;
      }
      return <Route path={this.props.path} component={this.props.component} />;
    }

    return <Redirect to={Routes.HOME} />;
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
});

const connector = connect(mapState);
export default connector(PrivateRoute);
