import "./styles.scss";

import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps,withRouter } from "react-router";
import HeaderBanner from "../../../common/components/headerBanner";
import BracedTitle from "../../../common/components/bracedTitle";
import { Routes } from "../../../common/enums/routes.enum";
import { apiFamilies } from "../../../productCatalog/constants/api-family.constant";
import { eventFamilies } from "../../../productCatalog/constants/event-family.constant";
import { ReducerAction } from "../../../reducer/enums/reducer-action.enum";
import { IReducerState } from "../../../reducer/interfaces/reducer.interface";
import { connect, ConnectedProps } from "react-redux";
import { statuses } from "../../../myOrganization/clientSettings/constants/userStatus.constant";
interface IProps {}
type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux,RouteComponentProps { }
interface Section {
  title: JSX.Element;
  routes: {
    name: JSX.Element;
    path: Routes | string;
  }[];
}

class SiteMap extends Component<IProps> {
  constructor(props: IProps) {
    super(props);
  }
  private sections: Section[] = [
    {
      title: <FormattedMessage id="siteMap.apiCatalog" />,
      routes: apiFamilies.map((apiFamily) => ({
        name: apiFamily.name,
        path: `${Routes.PRODUCTS}/${apiFamily.id}`,
      })),
    },

    {
      title: <FormattedMessage id="siteMap.eventCatalog" />,
      routes: eventFamilies.map((eventFamily) => ({
        name: eventFamily.name,
        path: `${Routes.PRODUCTS}/${eventFamily.id}`,
      })),
    },

    {
      title: <FormattedMessage id="roadmap" />,
      routes: [
        {
          name: <FormattedMessage id="roadmap" />,
          path: Routes.ROADMAP,
        },
      ],
    },

    {
      title: <FormattedMessage id="commercialOffers" />,
      routes: [
        {
          name: <FormattedMessage id="commercialOffers" />,
          path: Routes.COMMERCIAL_OFFERS,
        },
        {
          name: <FormattedMessage id="subscriptionRequest" />,
          path: Routes.SUBSCRIPTION_REQUEST,
        },
      ],
    },

    {
      title: <FormattedMessage id="myOrganization" />,
      routes: [
        {
          name: <FormattedMessage id="myOrganization.apiApplications" />,
          path: Routes.API_APPLICATIONS,
        },
        {
          name: <FormattedMessage id="myOrganization.eventApplications" />,
          path: Routes.EVENT_APPLICATIONS,
        },
        {
          name: <FormattedMessage id="myOrganization.businessPartners" />,
          path: Routes.BUSINESS_PARTNERS,
        },
        {
          name: <FormattedMessage id="myOrganization.clientSettings" />,
          path: Routes.CLIENT_SETTINGS,
        },
      ],
    },

    {
      title: <FormattedMessage id="contactUs" />,
      routes: [
        {
          name: <FormattedMessage id="contactUs.businessRequest" />,
          path: Routes.BUSINESS_REQUEST,
        },
        {
          name: <FormattedMessage id="contactUs.reportIssue" />,
          path: Routes.REPORT_ISSUE,
        },
      ],
    },

    {
      title: <FormattedMessage id="help" />,
      routes: [
        {
          name: <FormattedMessage id="guide.title" />,
          path: Routes.STARTING_GUIDE,
        },
        {
          name: <FormattedMessage id="usageGuide.head.title" />,
          path: Routes.USAGE_GUIDE,
        },
        {
          name: <FormattedMessage id="faq" />,
          path: Routes.FAQ,
        },
        {
          name: <FormattedMessage id="apiStatus.title" />,
          path: Routes.API_STATUS,
        },
        {
          name: <FormattedMessage id="releaseNotes.title" />,
          path: Routes.RELEASE_NOTES,
        },
      ],
    },

    {
      title: <FormattedMessage id="siteMap.accountCreation" />,
      routes: [
        {
          name: <FormattedMessage id="gettingStarted" />,
          path: Routes.GETTING_STARTED,
        },
        {
          name: <FormattedMessage id="termsAndConditions" />,
          path: Routes.TERMS_AND_CONDITIONS,
        },
      ],
    },
  ];
  
  getSection = () => {
    let filteredSections = [...this.sections];
    if(!this.props.user){ 
      filteredSections = this.sections.filter((section) => {
        const isMyOrganization = section.title.props.id === 'myOrganization'; // Replace 'contactUs' with the actual id you are using for "Contact Us"
        const isCommercialOffers = section.title.props.id === 'commercialOffers';
        return !isCommercialOffers && !isMyOrganization; // Exclude sections that match either condition
      });
      const helpSectionIndex = filteredSections.findIndex(section => section.title.props.id === "help");
        // If the "help" section is found, filter out the "apiStatus" route
        if (helpSectionIndex !== -1) {
          filteredSections[helpSectionIndex].routes = filteredSections[helpSectionIndex].routes.filter(route => {
            // Filter out the route with the name "apiStatus"
            return route.name.props.id !== 'apiStatus.title';
          });
        }
    }
    return filteredSections.map((section,index)=>
      <div key={`site-map-section-${index}`}>
        <BracedTitle text={section.title} />

        <div>
          <ul>
            {section.routes.map((route) => {
              if(this.props.user?.clientStatus===statuses.DEACTIVATED
                && (route.name.props.id === 'myOrganization.clientSettings'
                    || route.name.props.id === 'myOrganization.businessPartners')
              )
              {
                return null;
              }
              return (
              <li key={`li-${route.path}`}>
                <a href={route.path}>{route.name}</a>
              </li>
              );
            })}
          </ul>
        </div>
      </div>
    
    );
  };

  render() {
    return (
      <div id="site-map">
        <HeaderBanner>
          <FormattedMessage id="siteMap" />
        </HeaderBanner>

        <div className="App-content">
          <div className="content">{this.getSection()}</div>
        </div>
      </div>
    );
  }
}
const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});
const mapDispatch = {
  disconnectUser: () => ({
    type: ReducerAction.USER_LOGGED_OUT,
  }),
};
const connector = connect(mapState, mapDispatch);
export default withRouter(connector(SiteMap));
