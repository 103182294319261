import { FormattedMessage } from "react-intl";
import React from "react";

import {
  DocumentsLogo,
  InvoiceLogo,
  PriceLogo,
  ReferentialLogo,
  RoutesLogo,
  TransportExecutionLogo,
  VisibilityLogo,
  CapacityVesselLogo
} from "../../../assets/icons";
import { IProductsFamily } from "../interfaces/products-family.interface";
import { ProductFamily } from "../enums/product-family.enum";

export const apiFamilies: IProductsFamily[] = [
  {
    id: "allocation",
    productFamily: ProductFamily.ALLOCATION,
    name: <FormattedMessage id="apiCatalog.allocation" />,
    image: CapacityVesselLogo,
  },
  {
    id: "schedules",
    productFamily: ProductFamily.SCHEDULES,
    name: <FormattedMessage id="apiCatalog.schedules" />,
    image: RoutesLogo,
  },
  {
    id: "pricing",
    productFamily: ProductFamily.PRICING,
    name: <FormattedMessage id="apiCatalog.pricing" />,
    image: PriceLogo,
  },
  {
    id: "transport-execution",
    productFamily: ProductFamily.TRANSPORT_EXECUTION,
    name: <FormattedMessage id="apiCatalog.transportExecution" />,
    image: TransportExecutionLogo,
  },
  {
    id: "bl-suites",
    productFamily: ProductFamily.BL_SUITE,
    name: <FormattedMessage id="apiCatalog.blSuite" />,
    image: DocumentsLogo,
  },
  {
    id: "visibility",
    productFamily: ProductFamily.VISIBILITY,
    name: <FormattedMessage id="apiCatalog.visibility" />,
    image: VisibilityLogo,
  },
  {
    id: "invoice",
    productFamily: ProductFamily.INVOICE,
    name: <FormattedMessage id="apiCatalog.invoice" />,
    image: InvoiceLogo,
  },
  {
    id: "referential",
    productFamily: ProductFamily.REFERENTIAL,
    name: <FormattedMessage id="apiCatalog.referential" />,
    image: ReferentialLogo,
  },
  {
    id: "Documents",
    productFamily: ProductFamily.DOCUMENTS,
    name: <FormattedMessage id="eventCatalog.document" />,
    image: ReferentialLogo,
  }
];
