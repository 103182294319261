import "./styles.scss";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { KeyboardArrowDown } from "@material-ui/icons";

import { IFaqQuestions } from "../../../../productRoadmap/interfaces/new-product.interface";
import TransitionContent from "../../../../common/components/transitionContent";

interface IProps extends RouteComponentProps {
  question: IFaqQuestions;
}

interface IStates {
  caseOpened: boolean;
}

class QuestionFaq extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      caseOpened: false,
    };
  }

  render() {
    return (
      <div className="family-block">
        <div
          className="question" 
          onClick={() => this.setState({ caseOpened: !this.state.caseOpened })}
        >
          <div className="question-title" dangerouslySetInnerHTML={{__html:this.props.question.question.replace(
                                    /<p>/g,
                                    '<p style="margin:0px">'
                                  )}}/>
          <KeyboardArrowDown
            className={this.state.caseOpened ? "opened" : ""}
          />
        </div>
        <TransitionContent isExpanded={this.state.caseOpened}>
          <div className="solution" dangerouslySetInnerHTML={{__html:this.props.question.answer.replace(
                                    /<p>/g,
                                    '<p style="margin:0px">'
                                  )}}/>
        </TransitionContent>
      </div>
    );
  }
}

export default withRouter(QuestionFaq);
