import { IPackageSelection, IPackage } from "../interfaces/package.interface";
import { SubscriptionRequestInputId } from "../../common/submodules/form/enums/form.enum";
import { Form } from "../../common/submodules/form/models/form.model";
import { PortalMessage } from "../../contactUs/enums/portal.message";

export class SubscriptionRequestDTO {
  private phone: string;

  private customerType: string;
  private street: string;
  private moreAddressInfo?: string;
  private countryCode: string;
  private city: string;
  private zipCode: string;

  private billingRepName: string;
  private billingRepEmail: string;
  private vatNumber: string;
  private portalMessage:string;
  private packageSelection: IPackageSelection[];

  constructor(
    form: Form,
    sameAddressSelected: boolean,
    isOnlyCustomOffers: boolean,
    packages: IPackage[],
    packageSelection: IPackageSelection[]
  ) {
    this.phone = form.getFormField(SubscriptionRequestInputId.PHONE)
      ?.value as string;

    this.customerType = form.getFormField(
      SubscriptionRequestInputId.COMPANY_TYPE
    )?.value as string;

    let billingRepName = form.getFormField(
      SubscriptionRequestInputId.BILLING_REPRESENTATIVE_NAME
    )?.value as string;

    let billingRepEmail = form.getFormField(
      SubscriptionRequestInputId.BILLING_REPRESENTATIVE_EMAIL
    )?.value as string;

    if (isOnlyCustomOffers) {
      billingRepName = form.getFormField(
        SubscriptionRequestInputId.REPRESENTATIVE_NAME
      )?.value as string;

      billingRepEmail = form.getFormField(
        SubscriptionRequestInputId.REPRESENTATIVE_EMAIL
      )?.value as string;
    }

    this.billingRepName = billingRepName;
    this.billingRepEmail = billingRepEmail;

    this.vatNumber = form.getFormField(SubscriptionRequestInputId.VAT_NUMBER)
      ?.value as string;

    let street = form.getFormField(SubscriptionRequestInputId.BILLING_ADDRESS)
      ?.value as string;

    let moreAddressInfo = form.getFormField(
      SubscriptionRequestInputId.BILLING_MORE_ADDRESS
    )?.value as string;

    let countryCode = form.getFormField(
      SubscriptionRequestInputId.BILLING_COUNTRY_CODE
    )?.value as string;

    let city = form.getFormField(SubscriptionRequestInputId.BILLING_CITY)
      ?.value as string;

    let zipCode = form.getFormField(SubscriptionRequestInputId.BILLING_ZIP_CODE)
      ?.value as string;

    if (sameAddressSelected) {
      street = form.getFormField(SubscriptionRequestInputId.ADDRESS)
        ?.value as string;

      moreAddressInfo = form.getFormField(
        SubscriptionRequestInputId.MORE_ADDRESS
      )?.value as string;

      countryCode = form.getFormField(SubscriptionRequestInputId.COUNTRY_CODE)
        ?.value as string;

      city = form.getFormField(SubscriptionRequestInputId.CITY)
        ?.value as string;

      zipCode = form.getFormField(SubscriptionRequestInputId.ZIP_CODE)
        ?.value as string;
    }

    this.street = street;
    if (moreAddressInfo !== "") this.moreAddressInfo = moreAddressInfo;
    this.countryCode = countryCode;
    this.city = city;
    this.zipCode = zipCode;

    this.packageSelection = packageSelection;
    this.portalMessage = PortalMessage.SUBSCRIPTION_REQUEST;
  }
}
