import "./styles.scss";

import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";

import { IHeaderMenuTab } from "../interfaces/header.interface";
import { Routes } from "../../common/enums/routes.enum";
import {
  BurgerIcon,
  CloseIcon,
  ColoredLogoIcon,
} from "../../../assets/icons";
import { TabValues } from "../enums/header.enum";
import headerMenu from "../constants/header-menu.constant";
import MenuTab from "./menuTab";
import { ScreenType } from "../../common/enums/screen-type.enum";
import CustomScrollBars from "../../common/components/customScrollBars";
import Profile from "./profile";
import { IReducerState } from "../../reducer/interfaces/reducer.interface";
import Utils from "../../../utils";
import { FormattedMessage } from "react-intl";
import { statuses } from "../../myOrganization/clientSettings/constants/userStatus.constant";

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IProps extends PropsFromRedux, RouteComponentProps {}

interface IStates {
  isProfileVisible: boolean;
  isDeleteUserPopupVisible: boolean;
  openBurger: boolean;
}

class Header extends React.Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isProfileVisible: false,
      isDeleteUserPopupVisible: false,
      openBurger: false,
    };
  }

  private onRouteClick = (route: string): void => {
    this.props.history.push(route);
    this.closeBurgerMenu();
  };

  private closeBurgerMenu = () => {
    this.setState({ openBurger: false });
  };

  private getActiveTab = (): number | boolean => {
    const tabRoutes: { tabValue: TabValues; routes: string[] }[] = [];

    headerMenu.forEach((menuTab) => {
      const tabRoute: { tabValue: TabValues; routes: string[] } = {
        tabValue: menuTab.tabValue,
        routes: [],
      };

      const addRoute = (route: string | undefined) => {
        if (route) tabRoute.routes.push(route);
      };

      addRoute(menuTab.route);

      menuTab.subMenuSections?.forEach((subMenuSection) => {
        if (subMenuSection.route !== Routes.ROADMAP) {
          addRoute(subMenuSection.route);
        }

        subMenuSection.items?.forEach((item) => {
          addRoute(item.route);
        });
      });

      tabRoutes.push(tabRoute);
    });

    const pathnameIncludes = (routes: string[]) =>
      routes.some((route) => this.props.location.pathname.includes(route));

    for (const route of tabRoutes) {
      if (pathnameIncludes(route.routes)) return route.tabValue;
    }

    return false;
  };

  private getTab = (menuTab: IHeaderMenuTab) => {
    if (!menuTab.shouldBeConnected || !!this.props.user) {
      return (
        <MenuTab
          id={menuTab.id}
          key={`menu-tab-${menuTab.tabValue}`}
          menuTab={menuTab}
          isActive={this.getActiveTab() === menuTab.tabValue}
          onRouteClick={this.onRouteClick}
        />
      );
    }
  };

  private displayBurgerMenu = (): JSX.Element => {
    return (
      <div className="burger-menu">
        <div className="blue-head">
          <CloseIcon className="close" onClick={this.closeBurgerMenu} />
          <Profile burgerClosed={this.closeBurgerMenu} />
        </div>
        <CustomScrollBars>{this.displayTabs()}</CustomScrollBars>
      </div>
    );
  };

  private displayLogo = (): JSX.Element => {
    return (
      <div className="header-bottom-logo-icon">
        <ColoredLogoIcon onClick={() => this.onRouteClick(Routes.HOME)} />
      </div>
    );
  };

  private displayTabs = (): JSX.Element => {
    let filteredHeaderMenu=headerMenu;
    if (this.props.user?.clientStatus === statuses.DEACTIVATED) {
      filteredHeaderMenu = headerMenu.map(menu => ({
        ...menu,
        subMenuSections: menu.subMenuSections?.map(section => ({
          ...section,
          items: section.items?.filter(item =>
            item.route !== Routes.BUSINESS_PARTNERS && item.route !== Routes.CLIENT_SETTINGS
          )
        }))
      }));
    }
    return (
      <div className={`connection-block ${this.props.screenType}`}>
        <div className="header-bottom-menu">
          {filteredHeaderMenu.map((menuTab) => this.getTab(menuTab))}
        </div>
        {this.props.screenType !== ScreenType.COMPUTER && !this.props.user && (
          <>
            <div className="signIn" onClick={Utils.login}>
              <FormattedMessage id="signIn" />
            </div>
            <div
              className="gettingStarted"
              onClick={this.onGettingStartedClick}
            >
              <FormattedMessage id="gettingStarted" />
            </div>
          </>
        )}
      </div>
    );
  };

  private onGettingStartedClick = () => {
    this.setState({ openBurger: false });
    this.props.history.push(Routes.GETTING_STARTED);
  };

  render() {
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ openBurger: false })}
      >
        <div id="header">
          <nav className="header-bottom-content">
            {this.props.screenType === ScreenType.COMPUTER ? (
              <>
                <div className="left-content">
                  {this.displayLogo()}
                  {this.displayTabs()}
                </div>

                <div className="right-content">
                  <Profile burgerClosed={this.closeBurgerMenu} />
                </div>
              </>
            ) : (
              <>
                <div className="headerPhone">
                  <BurgerIcon
                    className="burger"
                    onClick={() => this.setState({ openBurger: true })}
                  />

                  {this.displayLogo()}

                  <div className="placeholder" />
                </div>

                {this.state.openBurger && this.displayBurgerMenu()}
              </>
            )}
          </nav>
        </div>
      </ClickAwayListener>
    );
  }
}

const mapState = (state: IReducerState) => ({
  user: state.user,
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(Header));
