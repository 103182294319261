import HttpUtils from "../../../utils/http.utils";
import { ICms } from "../interfaces/cms.interface";

export default class CmsAPI {
  public static async getFaqCms(): Promise<ICms> {
    return HttpUtils.get(`/cms/getCmsFaq`);
  }
  public static async getAllCms() :Promise<ICms[]> {
    return HttpUtils.get(`/cms/getAllCms`);
  }
  public static async getRoadmapCms(): Promise<ICms> {
    return HttpUtils.get(`/cms/getCmsRoadmap`);
  }
}
