import "./styles.scss";
import "swagger-ui-react/swagger-ui.css";

import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import { connect, ConnectedProps } from "react-redux";

import { Event, EventRow } from "../../../models/event.model";
import BracedTitle from "../../../../common/components/bracedTitle";
import Loader from "../../../../common/components/loader";
import NotFound from "../../../../common/components/notFound";
import EventAPI from "../../../apis/events.api";
import { Routes } from "../../../../common/enums/routes.enum";
import { ScreenType } from "../../../../common/enums/screen-type.enum";
import CustomScrollBars from "../../../../common/components/customScrollBars";
import EventsTable from "../../../../common/components/eventsTable";
import { IReducerState } from "../../../../reducer/interfaces/reducer.interface";
import { eventFamilies } from "../../../constants/event-family.constant";
import { newProductsFamily } from "../../../../productRoadmap/constants/new-products-eta.constant";
import { IObject } from "../../../interfaces/product-swagger.interface";
import LinkPart from "../apiDescription/linkPart";
import { regardingFaq } from "../../../../help/faq/constants/faq.constant";
import { CmsType } from "../../../enums/cms-type.enum";
import ReleaseNote from "../apiDescription/releaseNote";
import CmsAPI from "../../../../productRoadmap/apis/apiCms.api";
import { ICms } from "../../../../productRoadmap/interfaces/cms.interface";
type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps extends PropsFromRedux, RouteComponentProps {
  eventId: string;
  onBackClick?(): void;
}

interface IStates {
  event: Event | undefined;
  isLoading: boolean;
  listCms: ICms[] | undefined;
}

class EventDescription extends Component<IProps, IStates> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      event: undefined,
      isLoading: false,
      listCms:[]
    };
  }

  componentDidMount() {
    this.getDesc();
    CmsAPI.getAllCms().then((res) => {
      this.setState({ listCms: res });
    })
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IStates>
  ) {
    if (prevProps.eventId !== this.props.eventId) {
      this.getDesc();
    }
  }

  private getDesc = () => {
    this.setState({ isLoading: true }, () => {
      EventAPI.getEventSummary(this.props.eventId)
        .then((event) =>
          this.setState({
            event,
            isLoading: false,
          })
        )
        .catch(() => this.setState({ isLoading: false }));
    });
  };

  private onBackClick = () => {
    if (this.isSidePanel()) {
      this.props.onBackClick && this.props.onBackClick();
    } else {
      const eventFamily = eventFamilies.find(
        (eventFamily) =>
          eventFamily.productFamily ===
          this.state.event?.productFamily.toUpperCase()
      );

      if (eventFamily)
        this.props.history.push(
          eventFamily
            ? `${Routes.PRODUCTS}/${eventFamily.id}`
            : `${Routes.PRODUCTS}/${eventFamilies[0].id}`
        );
    }
  };

  private isSidePanel = () => {
    return (
      window.location.pathname.includes(Routes.COMMERCIAL_OFFERS) ||
      window.location.pathname.includes(Routes.API_APPLICATIONS)
    );
  };

  private displaySwagger = () => {
    this.props.history.push(
      `${Routes.PRODUCTS}/event/${this.props.eventId}/swagger`
    );
  };

  private getContent = () => {
    let availableEvents: EventRow[] = [];
    const headerTitleDcsa = ["eventDesc.classifier", "eventDesc.dcsaType", "eventDesc.document", "eventDesc.eventDomain", "eventDesc.relatedEvent"];
    const headerTitleCma = ["eventDesc.cmaClassifier", "eventDesc.cmaType", "eventDesc.cmaDocument", "eventDesc.eventDomain", "eventDesc.relatedEvent"];

    if (this.state.event && this.state.event.availableEvents) {
      switch (this.props.eventId) {
        case "equipmentEvent":
          availableEvents = this.state.event.availableEvents.equipment;
          break;

        case "shipmentEvent":
          availableEvents = this.state.event.availableEvents.shipment;
          break;

        default:
          availableEvents = this.state.event.availableEvents.transport;
          break;
      }
    }

    return (
      <div className={`App-content ${this.props.screenType}`}>
        {this.state.isLoading ? (
          <Loader />
        ) : this.state.event ? (
          <>
            <div className="header">
              <div className="title">{this.state.event.title}</div>

              {this.props.screenType !== ScreenType.PHONE && (
                <div className="view-swagger" onClick={this.displaySwagger}>
                  <FormattedMessage id="apiDesc.viewSwagger" />
                </div>
              )}
            </div>

            {this.state.event.virtualizedVersion && (
              <div className="tag">
                <div className="tag-title">
                  <FormattedMessage id="version" />
                </div>
                <div className="tag-content">
                  {this.state.event.virtualizedVersion}
                </div>
              </div>
            )}

            <div className="description">
              <BracedTitle
                text={<FormattedMessage id="apiDesc.description" />}
              />
              <div
                className="description-content"
                dangerouslySetInnerHTML={{
                  __html: this.state.event.description,
                }}
              />
            </div>

            {this.props.screenType !== ScreenType.PHONE && (
              <div className="available-events">
                {availableEvents.filter(event => event.eventDomain !== "Invoicing").length != 0 &&
                  <>
                    <BracedTitle
                      text={<FormattedMessage id="eventDesc.availableEvents" />}
                    />

                    <EventsTable events={availableEvents.filter(event => event.eventDomain !== "Invoicing")} familyName={this.props.eventId} headers={headerTitleDcsa} />
                  <br /><br />
                  </>
                }
                {availableEvents.filter(event => event.eventDomain === "Invoicing").length != 0 &&
                  <><BracedTitle
                    text={<FormattedMessage id="eventDesc.availableCmaEvents" />}
                  />
                    <div
                      className="description-content"
                    >{"These events aren't included currently by DCSA standard. CMA CGM decided to use the same structure shipment DCSA in order to be aligned with other events."}</div>
                    <EventsTable events={availableEvents.filter(event => event.eventDomain === "Invoicing")} familyName={this.props.eventId} headers={headerTitleCma} />
                  
                    <br /><br />
                  </>
                }
              </div>
            )}
            {this.displayLinks()}
            {this.state.event.release.length > 0 && (
                <div className="release">
                  <BracedTitle text={<FormattedMessage id="releaseNotes" />} />
                  <div className="release-content">
                    {this.state.event.release.map((release, index) => (
                      <ReleaseNote
                        key={`release-note-${index}`}
                        index={index}
                        release={release}
                      />
                    ))}
                  </div>
                </div>
              )}
          </>
        ) : (
          <NotFound text={<FormattedMessage id="noEventFound" />} />
        )}
      </div>
    );
  };
  private findRoadmapContent = () => {
    let listRoadmap: IObject[] = [];
    this.state.listCms?.find(e=>e.pageTitle===CmsType.ROADMAP)?.content?.forEach((family) =>
      family.products.forEach((elt) => {
        if (elt.id === (this.state.event?.id as string)) {
          listRoadmap.push({ title: elt.name, response: elt.description });
        }
      })
    );
    return listRoadmap;
  };

  private findFaqContent = () => {
    let listFaq: IObject[] = [];
    this.state.listCms?.find(e=>e.pageTitle===CmsType.FAQ)?.faqQuestions?.forEach((question) =>
      {
        if (question.referenceId === (this.state.event?.id as string)) {
          listFaq.push({ title: question.question, response: question.answer });
        }}
      );
    return listFaq;
  };
  private displayLinkPart = (listElement: IObject[], roadmap: boolean) => {
    return (
      <div className="link">
        {roadmap ? (
          <BracedTitle text={<FormattedMessage id="incomingUpdates" />} />
        ) : (
          <BracedTitle text={<FormattedMessage id="faq" />} />
        )}
        {listElement.map((elt, index) => (
          <LinkPart key={index} index={index} linkElement={elt} />
        ))}
        
        <br /><br />
      </div>
    );
  };
  private displayLinks = () => {
    const roadmapList = this.findRoadmapContent();
    const faqList = this.findFaqContent();
    return (
      <>
        {roadmapList.length >= 1 && this.displayLinkPart(roadmapList, true)}
        {faqList.length >= 1 && this.displayLinkPart(faqList, false)}
      </>
    );
  };
  render() {
    const finalContent = this.isSidePanel() ? (
      <CustomScrollBars>{this.getContent()}</CustomScrollBars>
    ) : (
      this.getContent()
    );
    return (
      <div id="event-description">
        <div className="back-container">
          <div className="back App-content" onClick={this.onBackClick}>
            {this.isSidePanel() ? (
              <>
                <CloseIcon />
                &nbsp;
                <span>
                  <FormattedMessage id="close" />
                </span>
              </>
            ) : (
              <>
                <ChevronLeftIcon />
                &nbsp;
                <span>
                  <FormattedMessage id="back" />
                </span>
              </>
            )}
          </div>
        </div>

        {finalContent}
      </div>
    );
  }
}

const mapState = (state: IReducerState) => ({
  screenType: state.screenType,
});

const connector = connect(mapState);
export default withRouter(connector(EventDescription));
